import { Button } from '@chakra-ui/react'
import { BiSave } from 'react-icons/bi'
import { useCreateSection } from '../hooks/useCreateSection'
import { BuildErrorModal } from './BuildErrorModal'
import { FullScreenLoading } from '../../../common/components/FullScreenLoading'

export const SaveFlow = () => {
  const { onSubmit, isLoading, isOpenBuildError, onCloseBuildError, buildErrorMessage } =
    useCreateSection()

  return (
    <>
      <Button
        isLoading={isLoading}
        leftIcon={<BiSave></BiSave>}
        cursor={'pointer'}
        onClick={onSubmit}
      >
        保存
      </Button>
      {isLoading && <FullScreenLoading />}
      <BuildErrorModal
        isOpen={isOpenBuildError}
        onClose={onCloseBuildError}
        buildErrorMessage={buildErrorMessage}
      ></BuildErrorModal>
    </>
  )
}
