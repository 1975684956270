import Axios from 'axios'
import Cookies from 'js-cookie'

const axios = Axios.create()

axios.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get('access_token')
    //blob:の場合はbaseURLを空にする
    if (!config.url?.startsWith('blob:')) {
      config.baseURL = process.env.REACT_APP_API_URL
    }

    config.headers['Authorization'] = `Bearer ${accessToken}`
    return config
  },
  (error) => {}
)

axios.interceptors.response.use(
  (response) => response, // 成功時のresponseはそのまま返す
  (error) => {
    if (error.response.statusText === 'Unauthorized') {
      window.location.href = String(process.env.REACT_APP_LOGIN_URL)
    }
    return Promise.reject(error)
  }
)
export { axios }
