import { useDisclosure, useToast } from '@chakra-ui/react'
import { ChangeEventHandler, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMyToast } from '../../../common/hooks/useMyToast'
import useSwrFetch from '../../../common/hooks/useSwrFetch'

export const useCreateFlow = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { showToast } = useMyToast()
  const navigate = useNavigate()

  const { isLoadingRequest: isCreateFlowLoading, request } = useSwrFetch('/')

  const [flowName, setFlowName] = useState('')
  const [slide, setSlide] = useState<File>()

  const [isFlowNameError, setIsFlowNameError] = useState(false)
  const [isSlideError, setIsSlideError] = useState(false)
  const [isSlideFileSizeError, setIsSlideFileSizeError] = useState(false)

  const handleFiles: ChangeEventHandler<HTMLInputElement> = (event) => {
    const file = event.currentTarget.files?.[0]
    if (!file) return

    if (!['svg', 'pdf'].some((type) => file.type.includes(type))) {
      event.currentTarget.value = ''
      showToast('error', 'svgまたはpdfファイルを選択してください')
      return
    }

    setSlide(file)
  }

  const resetFlow = () => {
    setFlowName('')
    setSlide(undefined)
    setIsFlowNameError(false)
    setIsSlideError(false)
    setIsSlideFileSizeError(false)
    onClose()
  }

  const onSubmit = async () => {
    if (!flowName) {
      setIsFlowNameError(true)
      showToast('error', '台本名を入力してください')
      return
    }

    if (!slide) {
      setIsSlideError(true)
      showToast('error', 'スライドファイルを選択してください')
      return
    }

    if (slide.size > 10485760) {
      // 10MB
      setIsSlideFileSizeError(true)
      showToast('error', 'ファイルサイズが10MB以上は許可されていません')
      return
    }

    const formData = new FormData()
    formData.append('file', slide)
    formData.append('flowName', flowName)

    try {
      const res = await request('/addFlow', 'post', formData)
      showToast('success', '台本を作成しました')
      navigate(`${res.data}/sections/0`)
    } catch {
      showToast('error', '台本の作成に失敗しました')
    }
  }

  return {
    onSubmit,
    flowName,
    setFlowName,
    handleFiles,
    isFlowNameError,
    isSlideError,
    isSlideFileSizeError,
    isCreateFlowLoading,
    isOpen,
    onOpen,
    onClose,
    resetFlow,
  }
}
