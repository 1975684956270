import { Card } from '@chakra-ui/card'
import { Center, Stack, Text } from '@chakra-ui/layout'
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  ModalBody,
  ModalHeader,
} from '@chakra-ui/react'
import { BsPlusCircle } from 'react-icons/bs'
import { MyModal } from '../../../common/components/Modal'
import { useCreateFlow } from '../hooks/useCreateFlow'

export const AddFlow = () => {
  const {
    onSubmit,
    flowName,
    setFlowName,
    handleFiles,
    isFlowNameError,
    isSlideError,
    isSlideFileSizeError,
    isOpen,
    onOpen,
    resetFlow,
    isCreateFlowLoading,
  } = useCreateFlow()

  return (
    <>
      <Stack w={'full'} h={'full'}>
        <Card cursor={'pointer'} onClick={onOpen} w={'full'} h={'160px'}>
          <Center my={'auto'}>
            <BsPlusCircle size={'32px'} />
            <Text ml={'2'} fontSize={'32px'}>
              新規作成
            </Text>
          </Center>
        </Card>
        <Text flexBasis={'6'}></Text>
      </Stack>

      <MyModal
        isOpen={isOpen}
        onClose={resetFlow}
        onSubmit={onSubmit}
        isLoading={isCreateFlowLoading}
      >
        <ModalHeader>台本新規作成</ModalHeader>
        <ModalBody>
          <FormControl isInvalid={isFlowNameError}>
            <FormLabel>台本名を入力する</FormLabel>
            <Input
              placeholder="台本名を入力してください"
              value={flowName}
              onChange={(event) => setFlowName(event.target.value)}
            />
            <FormErrorMessage>台本名を入力してください</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={isSlideError || isSlideFileSizeError}>
            <FormLabel>ファイルを追加する</FormLabel>
            <input type={'file'} accept={'.svg,.pdf'} onChange={handleFiles} />
            <FormHelperText>SVGまたはPDFファイルを追加(最大10M)</FormHelperText>
            <FormErrorMessage>ファイル(最大10M)を選択してください</FormErrorMessage>
          </FormControl>
        </ModalBody>
      </MyModal>
    </>
  )
}
