import { Box, Center, HStack, Spacer, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { BiArrowBack } from 'react-icons/bi'
import { SaveFlow } from './SaveFlow'

export const Header = () => {
  return (
    <Box bg={'#D9D9D9'} py={'2'} px={'6'} h={'full'}>
      <HStack h={'10'}>
        <Box cursor={'pointer'}>
          <Link to={'/'}>
            <Center>
              <BiArrowBack />
              <Text>台本に戻る</Text>
            </Center>
          </Link>
        </Box>
        <Spacer />
        <HStack gap={'4'}>
          <SaveFlow />
        </HStack>
      </HStack>
    </Box>
  )
}
