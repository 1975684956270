import { Box, Container, Flex, Grid, GridItem, Heading, Spacer, Stack } from '@chakra-ui/layout'
import { Link } from 'react-router-dom'
import { Loading } from '../../../common/components/Loading'
import useSwrFetch from '../../../common/hooks/useSwrFetch'
import { AddFlow } from './AddFlow'
import { Flow } from './Flow'

type Flow = {
  id: number
  name: string
  imageUrl: string
  agenda: string
  summaryFlag: boolean
}

const Flows = () => {
  const { data: flows, isLoading } = useSwrFetch<Flow[]>('/')

  return (
    <Box>
      <Flex borderBottom={'1px'} borderColor={'gray.200'} py={'2'}>
        <Spacer />
        <Flex mr={'8'} gap={'4'}>
          <Link to={String(process.env.REACT_APP_DASHBORD_URL)}>イベント一覧</Link>
        </Flex>
      </Flex>
      {isLoading ? (
        <Loading />
      ) : (
        <Container maxW={'5xl'} py={'8'}>
          <Stack gap={'24px'}>
            <Heading>台本作成</Heading>
            <Grid templateColumns={'repeat(4, 1fr)'} gap={4}>
              <GridItem w={'full'} h={'full'}>
                <AddFlow />
              </GridItem>
              {flows &&
                flows.map((flow) => (
                  <GridItem key={flow.id} w={'full'} h={'192px'}>
                    <Flow
                      name={flow.name}
                      imageUrl={flow.imageUrl}
                      id={flow.id}
                      agenda={flow.agenda}
                      summaryFlag={flow.summaryFlag}
                    />
                  </GridItem>
                ))}
            </Grid>
          </Stack>
        </Container>
      )}
    </Box>
  )
}

export default Flows
