import { DropResult } from 'react-beautiful-dnd'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import useSwrFetch from '../../../common/hooks/useSwrFetch'
import { currentSlideState } from '../../../common/stores/currentSlide'
import { FlowState } from '../types/flow'
import { useMyToast } from '../../../common/hooks/useMyToast'

export const useReArrangeAction = () => {
  const params = useParams()
  const { showToast } = useMyToast()

  const { data: flow, request, mutate } = useSwrFetch<FlowState, FormData>(`/show/${params.flowId}`)
  const currentSlide = useRecoilValue(currentSlideState)

  const onReArrangeAction = (result: DropResult) => {
    const { destination, source } = result

    // ドロップ先がない場合、操作をキャンセル
    if (!destination) {
      return
    }

    // ドラッグ元とドロップ先が同じ場合、操作をキャンセル
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    // データの順序更新
    if (flow) {
      const items = [...flow.slides[currentSlide].actions]
      const deleteItem = items.splice(result.source.index, 1)
      items.splice(destination.index, 0, deleteItem[0])
      const afterSlides = flow.slides.map((slide, index) => {
        if (index === currentSlide) {
          return { ...slide, actions: items }
        }
        return slide
      })
      mutate(`/show/${params.flowId}`, { slides: afterSlides }, false)
      const formData = new FormData()
      const json = JSON.stringify({ slides: afterSlides })
      formData.append('json', json)
      request(`/updateJson/${params.flowId}`, 'post', formData)
      showToast('success', 'アクション順序を更新しました')
    }
  }

  return {
    onReArrangeAction,
  }
}
