import {
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Icon,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
  Flex,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Box,
  Select,
  FormHelperText,
} from '@chakra-ui/react'
import { currentSlideState } from '../../../common/stores/currentSlide'
import { useRecoilValue } from 'recoil'
import { AiFillCloseCircle } from 'react-icons/ai'
import { MyModal } from '../../../common/components/Modal'
import { useDeleteAction } from '../hooks/useDeleteAction'
import useSwrFetch from '../../../common/hooks/useSwrFetch'
import { FlowState } from '../types/flow'
import { useParams } from 'react-router-dom'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useReArrangeAction } from '../hooks/useReArrangeAction'
import { useEditAction } from '../hooks/useEditAction'
import { useEffect } from 'react'

export const ActionsFlow = () => {
  const params = useParams()
  const { data: flow, isLoading } = useSwrFetch<FlowState, FormData>(`/show/${params.flowId}`)
  const currentSlide = useRecoilValue(currentSlideState)
  const {
    isOpenDeleteModal,
    onCloseDeleteModal,
    onOpenDeleteModal,
    deleteAction,
    deleteActionIndex,
    setDeleteActionIndex,
    isDeleteActionLoading,
  } = useDeleteAction()
  const { onReArrangeAction } = useReArrangeAction()
  const {
    audioIsOpen,
    resetAudioOnClose,
    editAudioAction,
    handleAudio,
    chatIsOpen,
    resetChatOnClose,
    waitIsOpen,
    resetWaitOnClose,
    editWaitAction,
    waitHour,
    waitMinute,
    waitSecond,
    setWaitHour,
    setWaitMinute,
    setWaitSecond,
    isAudioError,
    editChatAction,
    chatMessage,
    setChatMessage,
    timerIsOpen,
    resetTimerOnClose,
    editTimerAction,
    timerFormatType,
    setTimerFormatType,
    timerHour,
    timerMinute,
    timerSecond,
    setTimerHour,
    setTimerMinute,
    setTimerSecond,
    codeIsOpen,
    resetCodeOnClose,
    editCodeAction,
    codeScript,
    setCodeScript,
    onOpenEditActionModal,
    isEditActionLoading,
  } = useEditAction()

  if (isLoading) return <>...loading</>

  const actionType = (
    action: { type: string; param: any },
    slideIndex: number,
    actionIndex: number
  ) => {
    if (action.type === '音声') {
      return <audio controls src={action.param}></audio>
    } else if (action.type === '画面共有') {
      const screenShareCount = checkPreiviousShareScreenCount(slideIndex, actionIndex)
      return action.param + (screenShareCount % 2 === 0 ? 'ON' : 'OFF')
    } else {
      return action.param
    }
  }
  const checkPreiviousShareScreenCount = (slideIndex: number, actionIndex: number) => {
    let count = 1 // 初期処理で画面共有をONにするので1からスタート
    if (!flow) return count

    // スライドを最初から現在のスライドの1つ前まで走査
    for (let i = 0; i < slideIndex; i++) {
      const actions = flow.slides[i].actions
      for (let j = 0; j < actions.length; j++) {
        // 全てのアクションをカウント
        if (actions[j].type === '画面共有') {
          count++
        }
      }
    }

    // 現在のスライドで、現在のアクションより前のアクションをカウント
    const currentSlideActions = flow.slides[slideIndex].actions
    for (let j = 0; j < actionIndex; j++) {
      // 現在のアクションインデックスまで
      if (currentSlideActions[j].type === '画面共有') {
        count++
      }
    }

    return count
  }

  return (
    <>
      <Stack h={'full'}>
        <DragDropContext onDragEnd={onReArrangeAction}>
          <Table borderRadius={'md'}>
            <Thead position="sticky" top={0} zIndex="docked" bg={'gray.100'}>
              <Tr>
                <Th borderColor={'white'} borderWidth={'1px'}>
                  アクション名
                </Th>
                <Th borderColor={'white'} borderWidth={'1px'}>
                  データ内容
                </Th>
              </Tr>
            </Thead>
            <Droppable droppableId="droppableId">
              {(provided) => (
                <Tbody {...provided.droppableProps} ref={provided.innerRef} bg={'white'}>
                  {flow &&
                  flow.slides[currentSlide] &&
                  flow.slides[currentSlide].actions.length > 0 ? (
                    flow.slides[currentSlide].actions.map((action, index) => (
                      <Draggable key={String(index)} draggableId={String(index)} index={index}>
                        {(provided) => (
                          <Tr
                            key={index}
                            position={'relative'}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            role={'group'}
                          >
                            <Td
                              onClick={() =>
                                onOpenEditActionModal(action.type, action.param, index)
                              }
                              cursor={'pointer'}
                            >
                              {action.type}
                            </Td>
                            <Td whiteSpace={'pre-wrap'}>
                              {actionType(action, currentSlide, index)}
                              <Icon
                                cursor={'pointer'}
                                as={AiFillCloseCircle}
                                color={'#5A5A5A'}
                                position={'absolute'}
                                right={0}
                                top={0}
                                w={6}
                                h={6}
                                display={'none'}
                                _groupHover={{ display: 'block' }}
                                onClick={() => {
                                  onOpenDeleteModal(), setDeleteActionIndex(index)
                                }}
                              />
                            </Td>
                          </Tr>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={2} textAlign="center">
                        データがありません
                      </Td>
                    </Tr>
                  )}
                  {provided.placeholder}
                </Tbody>
              )}
            </Droppable>
          </Table>
        </DragDropContext>
      </Stack>
      <MyModal
        isOpen={isOpenDeleteModal}
        onClose={onCloseDeleteModal}
        onSubmit={() => deleteAction(deleteActionIndex)}
        isLoading={isDeleteActionLoading}
      >
        <ModalHeader>削除してもよろしいですか？</ModalHeader>
        <ModalBody>
          <Text>この操作は取り消せません</Text>
        </ModalBody>
      </MyModal>
      <MyModal
        isOpen={audioIsOpen}
        onClose={resetAudioOnClose}
        onSubmit={editAudioAction}
        isLoading={isEditActionLoading}
      >
        <ModalHeader>音声を編集</ModalHeader>
        <ModalBody>
          <FormControl isInvalid={isAudioError}>
            <FormLabel>ファイルを編集する</FormLabel>
            <input type={'file'} accept={'.wav'} onChange={handleAudio} />
            <FormErrorMessage>ファイルを選択してください</FormErrorMessage>
          </FormControl>
        </ModalBody>
      </MyModal>
      <MyModal
        isOpen={chatIsOpen}
        onClose={resetChatOnClose}
        onSubmit={editChatAction}
        isLoading={isEditActionLoading}
      >
        <ModalHeader>チャット編集</ModalHeader>
        <ModalBody>
          <FormLabel>自動送信チャットの設定</FormLabel>
          <Textarea value={chatMessage} onChange={(e) => setChatMessage(e.target.value)} />
        </ModalBody>
      </MyModal>
      <MyModal
        isOpen={waitIsOpen}
        onClose={resetWaitOnClose}
        onSubmit={editWaitAction}
        isLoading={isEditActionLoading}
      >
        <ModalHeader>待ち時間を編集</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>表示時間の設定</FormLabel>
            <Flex align={'end'} gap={'1'}>
              <NumberInput
                value={waitHour}
                onChange={(_, valueAsNumber) => setWaitHour(valueAsNumber)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Text>時</Text>
              <NumberInput
                value={waitMinute}
                onChange={(_, valueAsNumber) => setWaitMinute(valueAsNumber)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Text>分</Text>
              <NumberInput
                value={waitSecond}
                onChange={(_, valueAsNumber) => setWaitSecond(valueAsNumber)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Text>秒</Text>
            </Flex>
          </FormControl>
        </ModalBody>
      </MyModal>
      <MyModal
        isOpen={timerIsOpen}
        onClose={resetTimerOnClose}
        onSubmit={editTimerAction}
        isLoading={isEditActionLoading}
      >
        <ModalHeader>タイマーを編集</ModalHeader>
        <ModalBody>
          <FormControl>
            <Stack>
              <Box>
                <FormLabel>表示時間のフォーマット</FormLabel>
                <Select
                  value={timerFormatType}
                  onChange={(e) => setTimerFormatType(e.target.value)}
                >
                  <option value={'second'}>秒</option>
                  <option value={'minute'}>分：秒</option>
                  <option value={'hour'}>時：分：秒</option>
                </Select>
              </Box>
              <Box>
                <FormLabel>表示時間の設定</FormLabel>
                <Flex align={'end'} gap={'1'}>
                  {timerFormatType === 'hour' && (
                    <>
                      <NumberInput
                        min={0}
                        value={timerHour}
                        onChange={(_, value) => setTimerHour(value)}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                      <Text>時</Text>
                    </>
                  )}
                  {(timerFormatType === 'hour' || timerFormatType === 'minute') && (
                    <>
                      <NumberInput
                        min={0}
                        max={timerFormatType === 'hour' ? 59 : undefined}
                        value={timerMinute}
                        onChange={(_, value) => setTimerMinute(value)}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                      <Text>分</Text>
                    </>
                  )}

                  <NumberInput
                    min={0}
                    max={
                      timerFormatType === 'hour' || timerFormatType === 'minute' ? 59 : undefined
                    }
                    value={timerSecond}
                    onChange={(_, value) => setTimerSecond(value)}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text>秒</Text>
                </Flex>
              </Box>
            </Stack>
          </FormControl>
        </ModalBody>
      </MyModal>
      <MyModal
        isOpen={codeIsOpen}
        onClose={resetCodeOnClose}
        onSubmit={editCodeAction}
        isLoading={isEditActionLoading}
      >
        <ModalHeader>コード編集</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>コードを入力</FormLabel>
            <Textarea value={codeScript} onChange={(e) => setCodeScript(e.target.value)} />
            <FormHelperText color={'red'}>運営以外さわらないでください</FormHelperText>
          </FormControl>
        </ModalBody>
      </MyModal>
    </>
  )
}
