import { useDisclosure } from '@chakra-ui/react'
import { axios } from '../../../common/util/axios'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMyToast } from '../../../common/hooks/useMyToast'
type Params = {
  flowId: string
}

type ErrorResponse = {
  data?: {
    status?: string
    message?: string
  }
}

export const useCreateSection = () => {
  const navigate = useNavigate()
  const {
    isOpen: isOpenBuildError,
    onClose: onCloseBuildError,
    onOpen: onOpenBuildError,
  } = useDisclosure()
  const { flowId } = useParams<Params>()
  const { showToast } = useMyToast()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [buildErrorMessage, setBuildErrorMessage] = useState<string>('')

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      await axios.post(`/updateGo/${flowId}`)
      showToast('success', '保存しました')
      navigate('/')
    } catch (error: any) {
      const errResponse: ErrorResponse = error.response
      if (errResponse.data?.status === 'build error') {
        onOpenBuildError()
        setBuildErrorMessage(errResponse.data?.message || '')
      }
    } finally {
      setIsLoading(false)
    }
  }

  return { onSubmit, isLoading, isOpenBuildError, onCloseBuildError, buildErrorMessage }
}
