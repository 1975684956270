import { DropResult } from 'react-beautiful-dnd'
import { useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import useSwrFetch from '../../../common/hooks/useSwrFetch'
import { currentSlideState } from '../../../common/stores/currentSlide'
import { FlowState } from '../types/flow'
import { useMyToast } from '../../../common/hooks/useMyToast'

type Params = {
  flowId: string
}

export const useSlideReArrange = () => {
  const { flowId } = useParams<Params>()
  const { showToast } = useMyToast()
  const { data: flow, request, mutate } = useSwrFetch<FlowState, FormData>(`/show/${flowId}`)
  const setCurrentSlide = useSetRecoilState(currentSlideState)

  const rearrangeItems = (
    items: FlowState['slides'],
    sourceIndex: number,
    destinationIndex: number
  ): FlowState['slides'] => {
    const updatedItems = [...items]
    const [reorderedItem] = updatedItems.splice(sourceIndex, 1)
    updatedItems.splice(destinationIndex, 0, reorderedItem)
    return updatedItems
  }

  const onSlideReArrange = async (result: DropResult) => {
    const { destination, source } = result

    if (!destination) return
    if (destination.droppableId === source.droppableId && destination.index === source.index) return
    if (!flow) return

    const items = rearrangeItems(flow.slides, source.index, destination.index)
    mutate(`/show/${flowId}`, { slides: items }, false)

    const formData = new FormData()
    formData.append('json', JSON.stringify({ slides: items }))
    try {
      await request(`/updateJson/${flowId}`, 'post', formData)
      setCurrentSlide(destination.index)
      showToast('success', 'ファイルの順序を変更しました')
    } catch (error) {
      showToast('error', 'エラーが発生しました。再度試してください。')
    }
  }

  return {
    onSlideReArrange,
  }
}
