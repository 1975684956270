import { useParams } from 'react-router-dom'
import useSwrFetch from '../../../common/hooks/useSwrFetch'
import { FlowState } from '../types/flow'

export const useGetFlowContents = () => {
  const params = useParams()
  const { data, isLoading, request, mutate } = useSwrFetch<FlowState, FormData>(
    `/show/${params.flowId}`
  )

  return { data, isLoading, request, mutate }
}
