import { useDisclosure } from '@chakra-ui/react'
import { ChangeEventHandler, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import useSwrFetch from '../../../common/hooks/useSwrFetch'
import { currentSlideState } from '../../../common/stores/currentSlide'
import { FlowState } from '../types/flow'
import { useMyToast } from '../../../common/hooks/useMyToast'
type Params = {
  flowId: string
}

export const useCreateSlide = () => {
  const { flowId } = useParams<Params>()
  const { showToast } = useMyToast()

  const {
    isOpen: isOpenCreateSlideModal,
    onOpen: onOpenCreateSlideModal,
    onClose: onCloseCreateSlideModal,
  } = useDisclosure()

  const {
    data: flow,
    request,
    isLoadingRequest: isCreateSlideLoading,
  } = useSwrFetch<FlowState, FormData>(`/show/${flowId}`)

  const setCurrentSlide = useSetRecoilState(currentSlideState)
  const [slide, setSlide] = useState<File>()

  const isValidFile = (file: File): boolean => {
    return file.type.includes('svg') || file.type.includes('pdf')
  }

  const handleFiles: ChangeEventHandler<HTMLInputElement> = (event) => {
    const files = event.currentTarget.files
    if (!files || files.length === 0) return

    const file = files[0]
    if (!isValidFile(file)) {
      event.currentTarget.value = ''
      alert('svgまたはpdfファイルを選択してください')
      return
    }
    setSlide(file)
  }

  const onCreateSlide = async () => {
    if (!flow) return
    if (!slide) {
      alert('ファイルを選択してください')
      return
    }

    const fileFormData = new FormData()
    fileFormData.append('file', slide)

    try {
      const res = await request(`/uploadFile/${flowId}`, 'post', fileFormData)
      const slidePaths = res.data.paths as string[]

      const formData = new FormData()
      const json = JSON.stringify({
        slides: [
          ...flow.slides,
          ...slidePaths.map((slidePath) => ({ id: slidePath, actions: [] })),
        ],
      })
      formData.append('json', json)

      await request(`/updateJson/${flowId}`, 'post', formData)
      showToast('success', 'ファイルを追加しました')
      setCurrentSlide(flow.slides.length)
    } catch (error: any) {
      console.log(error)
      showToast('error', error?.response?.data?.message || 'エラーが発生しました')
    } finally {
      onCloseCreateSlideModal()
    }
  }

  return {
    isOpenCreateSlideModal,
    isCreateSlideLoading,
    onOpenCreateSlideModal,
    onCloseCreateSlideModal,
    handleFiles,
    onCreateSlide,
  }
}
