import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

export const MyModal: FC<{
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  onSubmit: () => void
  isLoading?: boolean
}> = ({ isOpen, onClose, children, onSubmit, isLoading }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        {children}
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            取り消し
          </Button>
          <Button
            isLoading={isLoading}
            colorScheme="blue"
            onClick={() => {
              onSubmit()
            }}
          >
            完了
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
