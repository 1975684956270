export const format = () => {
  const timerFormat = (type: string, hour: number, min: number, sec: number) => {
    const paddingSecond = sec.toString().padStart(2, '0')
    const paddingMinute = min.toString().padStart(2, '0')
    const paddingHour = hour.toString().padStart(2, '0')

    switch (type) {
      case 'second':
        return paddingSecond
      case 'minute':
        return paddingMinute + ':' + paddingSecond
      case 'hour':
        return paddingHour + ':' + paddingMinute + ':' + paddingSecond
      default:
        return paddingSecond
    }
  }

  const timerFormatToType = (timerFormat: string) => {
    const split = timerFormat.split(':')
    if (split.length === 1) {
      return { type: 'second', hour: 0, min: 0, sec: Number(split[0]) }
    } else if (split.length === 2) {
      return { type: 'minute', hour: 0, min: Number(split[0]), sec: Number(split[1]) }
    } else {
      return { type: 'hour', hour: Number(split[0]), min: Number(split[1]), sec: Number(split[2]) }
    }
  }

  return { timerFormat, timerFormatToType }
}
