import {
  Box,
  Center,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  ModalBody,
  ModalHeader,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spacer,
  Stack,
  Text,
  Textarea,
  FormErrorMessage,
  FormHelperText,
  Input,
  Button,
  Heading
} from '@chakra-ui/react'
import { MdOutlineKeyboardVoice } from 'react-icons/md'
import { BsChatDots } from 'react-icons/bs'
import { AiOutlinePlus } from 'react-icons/ai'
import { BiTime } from 'react-icons/bi'
import { TbScreenShare } from 'react-icons/tb'
import { BiTimer } from 'react-icons/bi'
import { DiCodeBadge } from 'react-icons/di'
import { MyModal } from '../../../common/components/Modal'
import { useCreateAction } from '../hooks/useCreateAction'
import { BiSave } from 'react-icons/bi'
import { BiMicrophone } from "react-icons/bi";
import { BiMicrophoneOff } from "react-icons/bi";

export const AddActions = () => {
  const {
    addShareScreen,
    audioIsOpen,
    audioOnOpen,
    resetAudioOnClose,
    addAudioAction,
    handleAudio,
    screenIsOpen,
    screenOnOpen,
    screenOnClose,
    chatIsOpen,
    chatOnOpen,
    resetChatOnClose,
    waitIsOpen,
    waitOnOpen,
    resetWaitOnClose,
    addWaitAction,
    waitHour,
    waitMinute,
    waitSecond,
    setWaitHour,
    setWaitMinute,
    setWaitSecond,
    isAudioError,
    addChatAction,
    chatMessage,
    setChatMessage,
    timerIsOpen,
    timerOnOpen,
    resetTimerOnClose,
    addTimerAction,
    timerFormatType,
    setTimerFormatType,
    timerHour,
    timerMinute,
    timerSecond,
    setTimerHour,
    setTimerMinute,
    setTimerSecond,
    codeIsOpen,
    codeOnOpen,
    resetCodeOnClose,
    addCodeAction,
    codeScript,
    setCodeScript,
    isCreateActionLoading,
    micinAudioState,
    handleStart,
    handleStop,
    handleRemove,
    micinAudiofile,
    audioPlayerRef
  } = useCreateAction()

  return (
    <>
      <Stack>
        <Text fontSize={'2xl'}>アクションを追加</Text>
        <Flex
          borderBottom={'1px'}
          borderColor={'gray.200'}
          cursor={'pointer'}
          onClick={audioOnOpen}
        >
          <HStack py={'1'}>
            <MdOutlineKeyboardVoice />
            <Text>音声追加</Text>
          </HStack>
          <Spacer />
          <Center>
            <AiOutlinePlus />
          </Center>
        </Flex>
        <Flex borderBottom={'1px'} borderColor={'gray.200'} cursor={'pointer'} onClick={chatOnOpen}>
          <HStack py={'1'}>
            <BsChatDots />
            <Text>チャット設定</Text>
          </HStack>
          <Spacer />
          <Center>
            <AiOutlinePlus />
          </Center>
        </Flex>
        <Flex borderBottom={'1px'} borderColor={'gray.200'} cursor={'pointer'} onClick={waitOnOpen}>
          <HStack py={'1'}>
            <BiTime />
            <Text>待ち時間</Text>
          </HStack>
          <Spacer />
          <Center>
            <AiOutlinePlus />
          </Center>
        </Flex>
        <Flex
          borderBottom={'1px'}
          borderColor={'gray.200'}
          cursor={'pointer'}
          onClick={screenOnOpen}
        >
          <HStack py={'1'}>
            <TbScreenShare />
            <Text>画面共有設定</Text>
          </HStack>
          <Spacer />
          <Center>
            <AiOutlinePlus />
          </Center>
        </Flex>
        <Flex
          borderBottom={'1px'}
          borderColor={'gray.200'}
          cursor={'pointer'}
          onClick={timerOnOpen}
        >
          <HStack py={'1'}>
            <BiTimer />
            <Text>タイマー</Text>
          </HStack>
          <Spacer />
          <Center>
            <AiOutlinePlus />
          </Center>
        </Flex>
        <Flex borderBottom={'1px'} borderColor={'gray.200'} cursor={'pointer'} onClick={codeOnOpen}>
          <HStack py={'1'}>
            <DiCodeBadge />
            <Text>コード</Text>
          </HStack>
          <Spacer />
          <Center>
            <AiOutlinePlus />
          </Center>
        </Flex>
      </Stack>
      <MyModal
        isOpen={audioIsOpen}
        onClose={resetAudioOnClose}
        onSubmit={addAudioAction}
        isLoading={isCreateActionLoading}
      >
        <ModalHeader>音声を追加</ModalHeader>
        <ModalBody>
          <Flex
            marginBottom={'20px'}
          >
            <FormControl isInvalid={isAudioError}>
            <Heading as='h3' size='sm' marginBottom={'5px'}>音声ファイルを追加する</Heading>
              <input type={'file'} accept={'.wav'} onChange={handleAudio} />
              <FormErrorMessage>ファイルを選択してください</FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex
              marginBottom={'40px'}
              flexFlow={'column'}
              alignItems={'left'}
          >
            <Heading as='h3' size='sm' marginBottom={'5px'}>直接録音する</Heading>
          <Button
            leftIcon={<BiMicrophone></BiMicrophone>}
            cursor={'pointer'}
            onClick={handleStart}
            hidden={!micinAudioState}
            width={'fit-content'}
          >
            録音
          </Button>
          <Button
            leftIcon={<BiMicrophoneOff></BiMicrophoneOff>}
            cursor={'pointer'}
            onClick={handleStop}
            disabled={micinAudioState}
            hidden={micinAudioState}
            width={'fit-content'}

          >
            停止
          </Button>
          </Flex>
          <audio ref={audioPlayerRef} controls />

        </ModalBody>
      </MyModal>
      <MyModal
        isOpen={chatIsOpen}
        onClose={resetChatOnClose}
        onSubmit={addChatAction}
        isLoading={isCreateActionLoading}
      >
        <ModalHeader>チャット追加</ModalHeader>
        <ModalBody>
          <FormLabel>自動送信チャットの設定</FormLabel>
          <Input value={chatMessage} onChange={(e) => setChatMessage(e.target.value)} />
        </ModalBody>
      </MyModal>
      <MyModal
        isOpen={waitIsOpen}
        onClose={resetWaitOnClose}
        onSubmit={addWaitAction}
        isLoading={isCreateActionLoading}
      >
        <ModalHeader>待ち時間を追加</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>表示時間の設定</FormLabel>
            <Flex align={'end'} gap={'1'}>
              <NumberInput
                value={waitHour}
                onChange={(_, valueAsNumber) => setWaitHour(valueAsNumber)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Text>時</Text>
              <NumberInput
                value={waitMinute}
                onChange={(_, valueAsNumber) => setWaitMinute(valueAsNumber)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Text>分</Text>
              <NumberInput
                value={waitSecond}
                onChange={(_, valueAsNumber) => setWaitSecond(valueAsNumber)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Text>秒</Text>
            </Flex>
          </FormControl>
        </ModalBody>
      </MyModal>
      <MyModal
        isOpen={screenIsOpen}
        onClose={screenOnClose}
        onSubmit={addShareScreen}
        isLoading={isCreateActionLoading}
      >
        <ModalHeader>画面共有設定を切り替えます</ModalHeader>
        <ModalBody>
          <FormLabel>※開始時は「スライド表示 : ON」から始まります</FormLabel>
        </ModalBody>
      </MyModal>
      <MyModal
        isOpen={timerIsOpen}
        onClose={resetTimerOnClose}
        onSubmit={addTimerAction}
        isLoading={isCreateActionLoading}
      >
        <ModalHeader>タイマーを追加</ModalHeader>
        <ModalBody>
          <FormControl>
            <Stack>
              <Box>
                <FormLabel>表示時間のフォーマット</FormLabel>
                <Select
                  value={timerFormatType}
                  onChange={(e) => setTimerFormatType(e.target.value)}
                >
                  <option value={'second'}>秒</option>
                  <option value={'minute'}>分：秒</option>
                  <option value={'hour'}>時：分：秒</option>
                </Select>
              </Box>
              <Box>
                <FormLabel>表示時間の設定</FormLabel>
                <Flex align={'end'} gap={'1'}>
                  {timerFormatType === 'hour' && (
                    <>
                      <NumberInput
                        min={0}
                        value={timerHour}
                        onChange={(_, value) => setTimerHour(value)}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                      <Text>時</Text>
                    </>
                  )}
                  {(timerFormatType === 'hour' || timerFormatType === 'minute') && (
                    <>
                      <NumberInput
                        min={0}
                        max={timerFormatType === 'hour' ? 59 : undefined}
                        value={timerMinute}
                        onChange={(_, value) => setTimerMinute(value)}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                      <Text>分</Text>
                    </>
                  )}

                  <NumberInput
                    min={0}
                    max={
                      timerFormatType === 'hour' || timerFormatType === 'minute' ? 59 : undefined
                    }
                    value={timerSecond}
                    onChange={(_, value) => setTimerSecond(value)}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text>秒</Text>
                </Flex>
              </Box>
            </Stack>
          </FormControl>
        </ModalBody>
      </MyModal>
      <MyModal
        isOpen={codeIsOpen}
        onClose={resetCodeOnClose}
        onSubmit={addCodeAction}
        isLoading={isCreateActionLoading}
      >
        <ModalHeader>コード追加</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>コードを入力</FormLabel>
            <Textarea value={codeScript} onChange={(e) => setCodeScript(e.target.value)} />
            <FormHelperText color={'red'}>運営以外さわらないでください</FormHelperText>
          </FormControl>
        </ModalBody>
      </MyModal>
    </>
  )
}
