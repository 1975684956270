import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Icon,
  Center,
  Flex,
  Card,
  CardBody,
  useClipboard,
} from '@chakra-ui/react'
import { FC } from 'react'
import { IoIosInformationCircle } from 'react-icons/io'
import { HiOutlineClipboardCopy } from 'react-icons/hi'

export const BuildErrorModal: FC<{
  isOpen: boolean
  onClose: () => void
  buildErrorMessage: string
}> = ({ isOpen, onClose, buildErrorMessage }) => {
  const { onCopy, hasCopied } = useClipboard(buildErrorMessage)
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex>
            <Center>
              <Icon as={IoIosInformationCircle} color={'red.500'}></Icon>
              <Text color={'red.500'} fontSize={'2xl'}>
                エラーが発生しました
              </Text>
            </Center>
          </Flex>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Text fontSize={'xs'}>テキストをコピーして、担当者へご報告ください。</Text>
          <Card variant="outline">
            <CardBody>
              <Text fontSize={'lg'}>{buildErrorMessage}</Text>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter display="flex" justifyContent="center">
          <Center>
            <Button
              leftIcon={<HiOutlineClipboardCopy />}
              colorScheme="teal"
              variant="outline"
              onClick={onCopy}
            >
              {hasCopied ? 'コピーしました！' : 'テキストをコピー'}
            </Button>
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
