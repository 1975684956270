import { useDisclosure, useToast } from '@chakra-ui/react'

export const useMyToast = () => {
  const toast = useToast()

  const showToast = (status: 'success' | 'error', title: string) => {
    toast({
      position: 'top',
      status,
      title,
      isClosable: true,
    })
  }

  return { showToast }
}
