import { useDisclosure, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useMyToast } from '../../../common/hooks/useMyToast'
import useSwrFetch from '../../../common/hooks/useSwrFetch'

export const useOperationFlow = () => {
  const {
    isOpen: isOperationsOpen,
    onOpen: onOperationsOpen,
    onClose: onOperationsClose,
  } = useDisclosure()

  const {
    isOpen: isDeleteAlertOpen,
    onOpen: onDeleteAlertOpen,
    onClose: onDeleteAlertClose,
  } = useDisclosure()

  const {
    isOpen: isEditFlowNameOpen,
    onOpen: onEditFlowNameOpen,
    onClose: onEditFlowNameClose,
  } = useDisclosure()

  const {
    isOpen: isEditAgendaOpen,
    onOpen: onEditAgendaOpen,
    onClose: onEditAgendaClose,
  } = useDisclosure()

  const { showToast } = useMyToast()

  const { isLoadingRequest, request } = useSwrFetch('/')

  const [isFlowNameError, setIsFlowNameError] = useState<boolean>(false)
  const [isAgendaError, setIsAgendaError] = useState<boolean>(false)

  const handleError = (err: any, errorMsg: string) => {
    showToast('error', errorMsg)
    console.error(err)
  }

  const handleSuccess = (message: string) => {
    showToast('success', message)
  }

  const onEditAgenda = async (flowId: number, agenda: string, summaryFlag: boolean) => {
    if (agenda === '' && summaryFlag) {
      setIsAgendaError(true)
      return
    }

    const data = {
      summaryFlag,
      agenda,
    }

    try {
      const res = await request(`/editAgenda/${flowId}`, 'post', data)
      handleSuccess(res.data.message)
    } catch (err) {
      handleError(err, '議事録の編集に失敗しました')
    } finally {
      onEditAgendaClose()
      setIsAgendaError(false)
    }
  }

  const onEditFlowName = async (flowId: number, flowName: string) => {
    if (flowName === '') {
      setIsFlowNameError(true)
      return
    }

    const data = {
      flowName,
    }

    try {
      await request(`/editFlowName/${flowId}`, 'post', data)
      handleSuccess('台本名を編集しました')
    } catch (err) {
      handleError(err, '台本名の編集に失敗しました')
    } finally {
      onEditFlowNameClose()
      setIsFlowNameError(false)
    }
  }

  const handleFlowAction = async (
    endpoint: string,
    successMessage: string,
    errorMessage: string
  ) => {
    try {
      await request(endpoint, 'post')
      handleSuccess(successMessage)
    } catch (err) {
      handleError(err, errorMessage)
    } finally {
      onOperationsClose()
    }
  }

  const onCopyFlow = (flowId: number) => {
    handleFlowAction(`/copyFlow/${flowId}`, '台本をコピーしました', '台本のコピーに失敗しました')
  }

  const onDeleteFlow = (flowId: number) => {
    handleFlowAction(`/deleteFlow/${flowId}`, '台本を削除しました', '台本の削除に失敗しました')
  }

  return {
    isDeleteAlertOpen,
    onDeleteAlertOpen,
    onDeleteAlertClose,

    isEditFlowNameOpen,
    onEditFlowNameOpen,
    onEditFlowNameClose,

    isEditAgendaOpen,
    onEditAgendaOpen,
    onEditAgendaClose,

    onCopyFlow,
    onDeleteFlow,
    isOperationsOpen,
    onOperationsOpen,
    onOperationsClose,
    onEditFlowName,
    isFlowNameError,
    isLoadingRequest,
    onEditAgenda,
    isAgendaError,
  }
}
