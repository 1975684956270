import { useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import useSwrFetch from '../../../common/hooks/useSwrFetch'
import { currentSlideState } from '../../../common/stores/currentSlide'
import { FlowState } from '../types/flow'
import { useMyToast } from '../../../common/hooks/useMyToast'
const getFileNameFromPath = (filePath: string) => filePath.split('/').pop()

export const useDeleteAction = () => {
  const params = useParams()
  const { showToast } = useMyToast()
  const {
    data: flow,
    request,
    isLoadingRequest: isDeleteActionLoading,
  } = useSwrFetch<FlowState, FormData>(`/show/${params.flowId}`)
  const currentSlide = useRecoilValue(currentSlideState)
  const {
    isOpen: isOpenDeleteModal,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure()
  const [deleteActionIndex, setDeleteActionIndex] = useState(-1)

  const deleteAction = async (targetIndex: number) => {
    if (!flow || targetIndex === -1) return

    try {
      const actions = flow.slides[currentSlide].actions
      const action = actions[targetIndex]

      if (action.type === '音声') {
        const fileName = getFileNameFromPath(action.param)
        const deleteFormData = new FormData()
        deleteFormData.append('fileName', fileName as string)
        await request(`/deleteFile/${params.flowId}`, 'post', deleteFormData)
      }

      const updatedActions = actions.filter((_, index) => index !== targetIndex)
      const updatedSlides = flow.slides.map((slide, index) => ({
        ...slide,
        actions: index === currentSlide ? updatedActions : slide.actions,
      }))

      const formData = new FormData()
      formData.append('json', JSON.stringify({ slides: updatedSlides }))
      await request(`/updateJson/${params.flowId}`, 'post', formData)
      showToast('success', 'アクションを削除しました')
    } catch (error) {
      showToast('error', 'アクションの削除に失敗しました')
    } finally {
      onCloseDeleteModal()
    }
  }

  return {
    isOpenDeleteModal,
    onOpenDeleteModal,
    onCloseDeleteModal,
    deleteAction,
    deleteActionIndex,
    setDeleteActionIndex,
    isDeleteActionLoading,
  }
}
