import { Box, Center, Flex, Spinner, Text } from '@chakra-ui/react'

export const FullScreenLoading = () => {
  return (
    <Flex
      padding="0"
      marginInlineStart="0!important"
      position="fixed"
      top="0"
      right="0"
      bottom="0"
      left="0"
      backgroundColor="white"
      zIndex="9999"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <Center>
          <Spinner
            size="xl"
            color="green.700"
            thickness="8px"
            emptyColor="gray.200"
            textAlign={'center'}
          />
        </Center>
        <Text textAlign={'center'} marginTop={'36px'} fontSize={'lg'}>
          処理中です。しばらくお待ちください
        </Text>
      </Box>
    </Flex>
  )
}
