import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { Loading } from '../../../common/components/Loading'
import { useGetFlowContents } from '../hooks/useGetFlowContents'
import { ActionsFlow } from './ActionsFlow'
import { AddActions } from './AddActions'
import { Header } from './Header'
import { Slide } from './Slide'
import { Slides } from './Slides'

export const Section = () => {
  const { isLoading } = useGetFlowContents()

  return (
    <Flex h={'100vh'} bg={'#F5F5F5'} direction={'column'}>
      <Box flexBasis={'56px'}>
        <Header />
      </Box>
      {isLoading ? (
        <Loading />
      ) : (
        <Grid
          templateRows={'repeat(14, 1fr)'}
          templateColumns={'repeat(7, 1fr)'}
          gap={4}
          py={'4'}
          pr={'6'}
          h={'90%'}
        >
          <GridItem
            rowSpan={14}
            colSpan={1}
            borderRight={'1px'}
            borderColor={'gray.300'}
            h={'full'}
          >
            <Slides />
          </GridItem>
          <GridItem rowSpan={7} colSpan={3}>
            <AddActions></AddActions>
          </GridItem>
          <GridItem rowSpan={7} colSpan={3} ml={'4'}>
            <Slide />
          </GridItem>
          <GridItem rowSpan={1} colSpan={6}>
            <Text fontSize={'2xl'} mb={-2}>
              アクションリスト
            </Text>
          </GridItem>
          <GridItem rowSpan={6} colSpan={6} overflow={'auto'}>
            <ActionsFlow />
          </GridItem>
        </Grid>
      )}
    </Flex>
  )
}
