import {
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  ModalBody,
  ModalHeader,
  Stack,
  Text,
  Icon,
  AspectRatio,
  Skeleton,
  Center,
} from '@chakra-ui/react'
import { useRecoilState } from 'recoil'
import { AiFillCloseCircle } from 'react-icons/ai'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { MyModal } from '../../../common/components/Modal'
import { currentSlideState } from '../../../common/stores/currentSlide'
import { useGetFlowContents } from '../hooks/useGetFlowContents'
import { useCreateSlide } from '../hooks/useCreateSlide'
import { useDeleteSlide } from '../hooks/useDeleteSlide'
import { useSlideReArrange } from '../hooks/useReArrangeSlide'

export const Slides = () => {
  const { data: flow, isLoading } = useGetFlowContents()
  const {
    handleFiles,
    onCreateSlide,
    isOpenCreateSlideModal,
    isCreateSlideLoading,
    onOpenCreateSlideModal,
    onCloseCreateSlideModal,
  } = useCreateSlide()
  const {
    isOpenDeleteSlideModal,
    onCloseDeleteSlideModal,
    onOpenDeleteSlideModal,
    onDeleteSlide,
    isDeleteSlideLoading,
  } = useDeleteSlide()
  const { onSlideReArrange } = useSlideReArrange()

  const [currentSlide, setCurrentSlide] = useRecoilState(currentSlideState)

  if (isLoading) return <>...loading</>

  return (
    <>
      <Stack px={'1'} py={'2'} h={'full'}>
        <DragDropContext onDragEnd={onSlideReArrange}>
          <Droppable droppableId="droppableId">
            {(provided) => (
              <Stack
                pl={'1'}
                pr={'3'}
                py={'2'}
                overflow={'auto'}
                className="testListArea"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {flow &&
                  flow.slides.map(({ id, actions }, index) => {
                    return (
                      <Draggable key={id} draggableId={id} index={index}>
                        {(provided) => (
                          <div
                            className="testItem"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Flex
                              key={index}
                              gap={'2'}
                              cursor={'pointer'}
                              onClick={() => {
                                setCurrentSlide(index)
                              }}
                              position={'relative'}
                              role={'group'}
                            >
                              <Text fontWeight={'bold'}>{index + 1}</Text>
                              <AspectRatio ratio={16 / 9} w={'full'}>
                                <Image
                                  w={'160px'}
                                  h={'80px'}
                                  borderRadius={'md'}
                                  src={id}
                                  boxShadow={index === currentSlide ? 'outline' : 'none'}
                                  fallback={<Skeleton w={'full'} h={'full'}></Skeleton>}
                                />
                              </AspectRatio>
                              <Icon
                                as={AiFillCloseCircle}
                                color={'#5A5A5A'}
                                position={'absolute'}
                                right={0}
                                top={0}
                                w={6}
                                h={6}
                                display={'none'}
                                _groupHover={{ display: 'block' }}
                                onClick={() => {
                                  onOpenDeleteSlideModal(), setCurrentSlide(index)
                                }}
                              />
                            </Flex>
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
        <Center onClick={onOpenCreateSlideModal} cursor={'pointer'}>
          <Text fontWeight={'bold'}>スライドを追加</Text>
        </Center>
      </Stack>

      <MyModal
        isOpen={isOpenCreateSlideModal}
        onClose={onCloseCreateSlideModal}
        onSubmit={onCreateSlide}
        isLoading={isCreateSlideLoading}
      >
        <ModalHeader>スライドを追加</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>ファイルを追加する</FormLabel>
            <input type={'file'} accept={'.svg, .pdf'} onChange={handleFiles} />
            <FormHelperText>SVGまたはPDFファイルを追加(最大10M)</FormHelperText>
          </FormControl>
        </ModalBody>
      </MyModal>
      <MyModal
        isOpen={isOpenDeleteSlideModal}
        onClose={onCloseDeleteSlideModal}
        onSubmit={onDeleteSlide}
        isLoading={isDeleteSlideLoading}
      >
        <ModalHeader>削除してもよろしいですか？</ModalHeader>
        <ModalBody>
          <Text>この操作は取り消せません</Text>
        </ModalBody>
      </MyModal>
    </>
  )
}
