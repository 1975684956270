import { useDisclosure } from '@chakra-ui/react'
import { ChangeEventHandler, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import useSwrFetch from '../../../common/hooks/useSwrFetch'
import { currentSlideState } from '../../../common/stores/currentSlide'
import { format } from '../../../common/util/format'
import { FlowState } from '../types/flow'
import { useMyToast } from '../../../common/hooks/useMyToast'
export const useEditAction = () => {
  const params = useParams()

  const { showToast } = useMyToast()

  const { timerFormat, timerFormatToType } = format()

  const { isOpen: audioIsOpen, onOpen: audioOnOpen, onClose: audioOnClose } = useDisclosure()
  const { isOpen: waitIsOpen, onOpen: waitOnOpen, onClose: waitOnClose } = useDisclosure()
  const { isOpen: timerIsOpen, onOpen: timerOnOpen, onClose: timerOnClose } = useDisclosure()
  const { isOpen: chatIsOpen, onOpen: chatOnOpen, onClose: chatOnClose } = useDisclosure()
  const { isOpen: codeIsOpen, onOpen: codeOnOpen, onClose: codeOnClose } = useDisclosure()

  const {
    data: flow,
    request,
    isLoadingRequest: isEditActionLoading,
  } = useSwrFetch<FlowState, FormData>(`/show/${params.flowId}`)

  const currentSlide = useRecoilValue(currentSlideState)

  const [actionIndex, setActionIndex] = useState<number>(-1)

  const [audio, setAudio] = useState<File>()
  const [isAudioError, setIsAudioError] = useState(false)

  const [waitHour, setWaitHour] = useState(0)
  const [waitMinute, setWaitMinute] = useState(0)
  const [waitSecond, setWaitSecond] = useState(0)

  const [timerFormatType, setTimerFormatType] = useState('hour')
  const [timerHour, setTimerHour] = useState(0)
  const [timerMinute, setTimerMinute] = useState(0)
  const [timerSecond, setTimerSecond] = useState(0)

  const [chatMessage, setChatMessage] = useState('')

  const [codeScript, setCodeScript] = useState('')

  const handleAudio: ChangeEventHandler<HTMLInputElement> = (event) => {
    const files = event.currentTarget.files
    if (!files || files?.length === 0) return
    const file = files[0]

    // ドラッグ＆ドロップからファイル選択する際のファイル型を制限
    if (!file.type.includes('wav')) {
      event.currentTarget.value = ''
      showToast('error', 'wavファイルを選択してください')
      return
    }
    setAudio(file)
  }

  const resetAudioOnClose = () => {
    setAudio(undefined)
    setIsAudioError(false)
    audioOnClose()
  }

  const resetChatOnClose = () => {
    setChatMessage('')
    chatOnClose()
  }

  const resetWaitOnClose = () => {
    setWaitHour(0)
    setWaitMinute(0)
    setWaitSecond(0)
    waitOnClose()
  }

  const resetTimerOnClose = () => {
    setTimerHour(0)
    setTimerMinute(0)
    setTimerSecond(0)
    timerOnClose()
  }

  const resetCodeOnClose = () => {
    setCodeScript('')
    codeOnClose()
  }

  const updateJsonToFlow = async (slides: FlowState['slides'], actionType: string) => {
    const formData = new FormData()
    const json = JSON.stringify({ slides })
    formData.append('json', json)
    try {
      await request(`/updateJson/${params.flowId}`, 'post', formData)
      showToast('success', `${actionType}アクションを編集しました`)
    } catch (error) {
      showToast('error', `${actionType}アクションの編集に失敗しました`)
    }
  }

  const editActionToFlow = async (actionType: string, param: any) => {
    if (!flow) return

    const afterActions = flow.slides[currentSlide].actions.map((action, index) =>
      index === actionIndex ? { ...action, param } : action
    )
    const afterSlides = flow.slides.map((slide, index) => ({
      ...slide,
      actions: index === currentSlide ? afterActions : slide.actions,
    }))
    await updateJsonToFlow(afterSlides, actionType)
  }

  const editAudioAction = async () => {
    if (!flow) return

    if (audio === undefined) {
      setIsAudioError(true)
      return
    }

    const editFileFormData = new FormData()
    editFileFormData.append('file', audio)
    const deleteFilePath = flow.slides[currentSlide].actions[actionIndex].param
    const deleteFileName = deleteFilePath.split('/').pop()
    editFileFormData.append('fileName', deleteFileName)
    try {
      const res = await request(`/editFile/${params.flowId}`, 'post', editFileFormData)
      const audioPath = res.data.path
      await editActionToFlow('音声', audioPath)
    } catch (error) {
      showToast('error', 'ファイルのアップロードに失敗しました')
      return
    } finally {
      resetAudioOnClose()
    }
  }

  const editChatAction = async () => {
    const setMessage = chatMessage.replace(/\n/g, '') // 改行コードを削除
    await editActionToFlow('チャット', setMessage)
    resetChatOnClose()
  }

  const editWaitAction = async () => {
    if (!flow) return

    // 待ち時間アクション処理
    let totalWaitSec = 0
    if (waitHour) {
      totalWaitSec = totalWaitSec + waitHour * 3600
    }
    if (waitMinute) {
      totalWaitSec = totalWaitSec + waitMinute * 60
    }
    if (waitSecond) {
      totalWaitSec = totalWaitSec + waitSecond
    }
    await editActionToFlow('待ち時間', totalWaitSec)
    resetWaitOnClose()
  }

  const editTimerAction = async () => {
    const formattedTime = timerFormat(timerFormatType, timerHour, timerMinute, timerSecond)
    await editActionToFlow('タイマー', formattedTime)
    resetTimerOnClose()
  }

  const editCodeAction = async () => {
    await editActionToFlow('コード', codeScript)
    resetCodeOnClose()
  }

  const onOpenEditActionModal = (type: string, param: any, actionIndex: number) => {
    setActionIndex(actionIndex)

    switch (type) {
      case '音声':
        audioOnOpen()
        setAudio(param)
        break
      case 'チャット':
        chatOnOpen()
        setChatMessage(param)
        break
      case '待ち時間':
        waitOnOpen()
        setWaitHour(Math.floor(param / 3600))
        setWaitMinute(Math.floor((param % 3600) / 60))
        setWaitSecond(param % 60)
        break
      case 'タイマー':
        timerOnOpen()
        const { type, hour, min, sec } = timerFormatToType(param)
        setTimerFormatType(type)
        setTimerHour(hour)
        setTimerMinute(min)
        setTimerSecond(sec)
        break
      case 'コード':
        codeOnOpen()
        setCodeScript(param)
        break
      default:
        break
    }
  }

  return {
    audioIsOpen,
    audioOnOpen,
    resetAudioOnClose,
    editAudioAction,
    handleAudio,
    chatIsOpen,
    chatOnOpen,
    resetChatOnClose,
    waitIsOpen,
    waitOnOpen,
    resetWaitOnClose,
    editWaitAction,
    waitHour,
    waitMinute,
    waitSecond,
    setWaitHour,
    setWaitMinute,
    setWaitSecond,
    isAudioError,
    editChatAction,
    chatMessage,
    setChatMessage,
    timerIsOpen,
    timerOnOpen,
    resetTimerOnClose,
    editTimerAction,
    timerFormatType,
    setTimerFormatType,
    timerHour,
    timerMinute,
    timerSecond,
    setTimerHour,
    setTimerMinute,
    setTimerSecond,
    codeIsOpen,
    codeOnOpen,
    resetCodeOnClose,
    editCodeAction,
    codeScript,
    setCodeScript,
    isEditActionLoading,
    onOpenEditActionModal,
  }
}
