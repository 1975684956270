import { AxiosRequestConfig } from 'axios'
import { axios } from '../util/axios'
import useSWR, { SWRConfiguration, mutate as globalMutate } from 'swr'
import { useState } from 'react'

const fetcher = async <T>(url: string): Promise<T> => {
  const response = await axios.get(`${url}`)
  return response.data
}

type RequestMethod = 'post' | 'put' | 'patch' | 'delete'

const useSwrFetch = <T, U = T>(url: string, options?: SWRConfiguration) => {
  const { data, error, isLoading, mutate } = useSWR<T>(url, fetcher, options)
  const [isLoadingRequest, setIsLoadingRequest] = useState(false)

  const request = async (
    url: string,
    method: RequestMethod,
    data?: U,
    config?: AxiosRequestConfig
  ): Promise<any> => {
    const axiosConfig: AxiosRequestConfig = {
      url,
      method,
      data,
      ...config,
    }
    setIsLoadingRequest(true)

    try {
      data = await axios(axiosConfig)
      mutate()
      return data
    } catch (error) {
      console.log(error)
      throw error
    } finally {
      setIsLoadingRequest(false)
    }
  }

  return {
    data,
    isLoading,
    isLoadingRequest,
    error,
    mutate: globalMutate,
    request,
  }
}

export default useSwrFetch
