import { Image } from '@chakra-ui/image'
import { Stack, Text, Flex } from '@chakra-ui/layout'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Input,
  ModalBody,
  ModalHeader,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { GoThreeBars } from 'react-icons/go'
import { useNavigate } from 'react-router-dom'
import { useOperationFlow } from '../hooks/useOperationFlow'
import { MyModal } from '../../../common/components/Modal'
import FocusLock from 'react-focus-lock'

type FlowProps = {
  name: string
  imageUrl: string
  id: number
  agenda: string
  summaryFlag: boolean
}

export const Flow = ({ name, imageUrl, id, agenda, summaryFlag }: FlowProps) => {
  const firstFieldRef = React.useRef(null)
  const {
    onCopyFlow,
    isOperationsOpen,
    onOperationsOpen,
    onOperationsClose,
    isEditFlowNameOpen,
    onEditFlowNameClose,
    onEditFlowNameOpen,
    onEditFlowName,
    isFlowNameError,
    isDeleteAlertOpen,
    onDeleteAlertClose,
    onDeleteAlertOpen,
    onDeleteFlow,
    isLoadingRequest,
    isEditAgendaOpen,
    onEditAgendaOpen,
    onEditAgendaClose,
    onEditAgenda,
    isAgendaError,
  } = useOperationFlow()

  const [flowName, setFlowName] = useState(name)
  const [flowAgenda, setFlowAgenda] = useState(agenda)
  const [flowSummaryFlag, setFlowSummaryFlag] = useState(summaryFlag)
  console.log(flowSummaryFlag)
  const textStyle = {
    padding: '5px',
    background: 'none',
    border: '1px solid gainsboro',
    borderRadius: '5px',
  }

  const navigate = useNavigate()
  return (
    <Stack
      w={'full'}
      h={'full'}
      cursor={'pointer'}
      onClick={() => navigate(`${id}/sections/0`, { replace: true })}
    >
      <Flex role={'group'} position={'relative'}>
        <Image
          src={imageUrl}
          w={'full'}
          h={'160px'}
          fallback={<Skeleton w={'full'} h={'160px'}></Skeleton>}
        />
        <Popover
          isOpen={isOperationsOpen}
          initialFocusRef={firstFieldRef}
          onOpen={onOperationsOpen}
          onClose={onOperationsClose}
          placement={'right'}
          closeOnBlur={true}
        >
          <PopoverTrigger>
            <IconButton
              color={'#5A5A5A'}
              position={'absolute'}
              right={1}
              top={4}
              display={'none'}
              _groupHover={{ display: 'block' }}
              size={'sm'}
              aria-label={'menu'}
              icon={<Icon as={GoThreeBars} />}
              onClick={(e) => {
                e.stopPropagation()
              }}
            />
          </PopoverTrigger>
          <PopoverContent
            py={10}
            px={'2'}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <FocusLock returnFocus persistentFocus={false}>
              <PopoverArrow />
              <PopoverCloseButton
                onClick={(e) => {
                  e.stopPropagation()
                  onOperationsClose()
                }}
              />
              <Flex direction={'column'} gap={'4'}>
                <Button
                  colorScheme="orange"
                  variant={'outline'}
                  onClick={(e) => {
                    e.stopPropagation()
                    onEditFlowNameOpen()
                  }}
                  isLoading={isLoadingRequest}
                >
                  <Text fontWeight={'bold'}>名前の変更</Text>
                </Button>
                <Button
                  colorScheme="green"
                  variant={'outline'}
                  onClick={(e) => {
                    e.stopPropagation()
                    onEditAgendaOpen()
                  }}
                  isLoading={isLoadingRequest}
                >
                  <Text fontWeight={'bold'}>議事録の変更</Text>
                </Button>
                <Button
                  colorScheme="purple"
                  variant={'outline'}
                  onClick={(e) => {
                    e.stopPropagation()
                    onCopyFlow(id)
                  }}
                  isLoading={isLoadingRequest}
                >
                  <Text fontWeight={'bold'}>複製</Text>
                </Button>
                <Button
                  colorScheme="red"
                  variant={'outline'}
                  onClick={(e) => {
                    e.stopPropagation()
                    onDeleteAlertOpen()
                  }}
                  isLoading={isLoadingRequest}
                >
                  <Text fontWeight={'bold'}>削除</Text>
                </Button>
              </Flex>
            </FocusLock>
          </PopoverContent>
        </Popover>
      </Flex>
      <Text flexBasis={'6'} fontWeight={'bold'}>
        {name}
      </Text>
      <MyModal
        isOpen={isEditFlowNameOpen}
        onClose={onEditFlowNameClose}
        onSubmit={() => onEditFlowName(id, flowName)}
        isLoading={isLoadingRequest}
      >
        <ModalHeader>台本名の変更</ModalHeader>
        <ModalBody>
          <FormControl isInvalid={isFlowNameError}>
            <FormLabel>台本名を入力する</FormLabel>
            <Input
              placeholder="台本名を入力してください"
              value={flowName}
              onChange={(event) => setFlowName(event.target.value)}
            />
            <FormErrorMessage>台本名を入力してください</FormErrorMessage>
          </FormControl>{' '}
        </ModalBody>
      </MyModal>
      <MyModal
        isOpen={isEditAgendaOpen}
        onClose={onEditAgendaClose}
        onSubmit={() => onEditAgenda(id, flowAgenda, flowSummaryFlag)}
        isLoading={isLoadingRequest}
      >
        <ModalHeader>議事録の変更</ModalHeader>
        <ModalBody>
          <FormControl isInvalid={isAgendaError}>
            <FormLabel>
              <input
                type="checkbox"
                checked={flowSummaryFlag}
                onChange={() => setFlowSummaryFlag((prevState) => !prevState)}
              />
              &nbsp;議事録を作成する
            </FormLabel>
            <textarea
              disabled={!flowSummaryFlag}
              placeholder="アジェンダを入力してください"
              rows={5}
              cols={44}
              style={textStyle}
              value={flowAgenda}
              onChange={(event) => setFlowAgenda(event.target.value)}
            />
            <FormErrorMessage>アジェンダを入力してください</FormErrorMessage>
          </FormControl>
        </ModalBody>
      </MyModal>
      <MyModal
        isOpen={isDeleteAlertOpen}
        onClose={onDeleteAlertClose}
        onSubmit={() => onDeleteFlow(id)}
        isLoading={isLoadingRequest}
      >
        <ModalHeader>削除してもよろしいですか？</ModalHeader>
        <ModalBody>
          <Text>この操作は取り消せません</Text>
        </ModalBody>
      </MyModal>
    </Stack>
  )
}
