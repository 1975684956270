import { useDisclosure, useToast } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import useSwrFetch from '../../../common/hooks/useSwrFetch'
import { currentSlideState } from '../../../common/stores/currentSlide'
import { FlowState } from '../types/flow'
import { useMyToast } from '../../../common/hooks/useMyToast'

type Params = {
  flowId: string
}

export const useDeleteSlide = () => {
  const { flowId } = useParams<Params>()
  const { showToast } = useMyToast()

  const {
    data: flow,
    request,
    isLoadingRequest: isDeleteSlideLoading,
  } = useSwrFetch<FlowState, FormData>(`/show/${flowId}`)

  const [currentSlide, setCurrentSlide] = useRecoilState(currentSlideState)

  const {
    isOpen: isOpenDeleteSlideModal,
    onOpen: onOpenDeleteSlideModal,
    onClose: onCloseDeleteSlideModal,
  } = useDisclosure()

  const onDeleteSlide = async () => {
    if (!flow) return

    if (flow.slides.length <= 1) {
      showToast('error', 'スライドが1枚しかないため削除できません')
      onCloseDeleteSlideModal()
      return
    }

    const filePath = flow.slides[currentSlide]?.id
    const fileName = filePath?.split('/').pop()
    if (!fileName) return

    const deleteFormData = new FormData()
    deleteFormData.append('fileName', fileName)
    try {
      await request(`/deleteFile/${flowId}`, 'post', deleteFormData)
    } catch (error) {
      showToast('error', 'ファイルの削除に失敗しました')
      return
    }

    const afterSlides = flow.slides.filter((_, index) => currentSlide !== index)
    const formData = new FormData()
    formData.append('json', JSON.stringify({ slides: afterSlides }))

    try {
      await request(`/updateJson/${flowId}`, 'post', formData)
      showToast('success', 'スライドを削除しました')
      setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : 0)
    } catch (error) {
      showToast('error', 'スライド情報の更新に失敗しました')
    }

    onCloseDeleteSlideModal()
  }

  return {
    isOpenDeleteSlideModal,
    onOpenDeleteSlideModal,
    onCloseDeleteSlideModal,
    onDeleteSlide,
    isDeleteSlideLoading,
  }
}
