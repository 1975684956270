import { useDisclosure } from '@chakra-ui/react'
import { ChangeEventHandler, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import useSwrFetch from '../../../common/hooks/useSwrFetch'
import { currentSlideState } from '../../../common/stores/currentSlide'
import { FlowState } from '../types/flow'
import { useMyToast } from '../../../common/hooks/useMyToast'
type Params = {
  flowId: string
}

export const useEditSlide = () => {
  const { flowId } = useParams<Params>()
  const { showToast } = useMyToast()
  const currentSlide = useRecoilValue(currentSlideState)

  const {
    isOpen: isOpenEditSlideModal,
    onOpen: onOpenEditSlideModal,
    onClose: onCloseEditSlideModal,
  } = useDisclosure()

  const {
    data: flow,
    request,
    isLoadingRequest: isEditSlideLoadingRequest,
  } = useSwrFetch<FlowState, FormData>(`/show/${flowId}`)

  const [slide, setSlide] = useState<File>()

  const isValidFile = (file: File): boolean => {
    if (file.type.includes('svg')) {
      return true
    }
    alert('svgファイルを選択してください')
    return false
  }

  const handleFiles: ChangeEventHandler<HTMLInputElement> = (event) => {
    const files = event.currentTarget.files
    if (!files || files?.length === 0) return
    const file = files[0]
    if (isValidFile(file)) {
      setSlide(file)
    } else {
      event.currentTarget.value = ''
    }
  }

  const onEditSlide = async () => {
    if (!flow || !slide) {
      alert('ファイルを選択してください')
      return
    }

    const deleteFilePath = flow.slides[currentSlide]?.id
    const deleteFileName = deleteFilePath?.split('/').pop()
    if (!deleteFileName) return

    const formData = new FormData()
    formData.append('file', slide)
    formData.append('fileName', deleteFileName)

    try {
      const { data } = await request(`/editFile/${flowId}`, 'post', formData)
      const filePath = data?.path as string

      const updatedSlides = flow.slides.map((slide, index) =>
        index === currentSlide ? { id: filePath, actions: slide.actions } : slide
      )

      const updateFormData = new FormData()
      updateFormData.append('json', JSON.stringify({ slides: updatedSlides }))
      await request(`/updateJson/${flowId}`, 'post', updateFormData)
      showToast('success', 'スライドを編集しました')
      onCloseEditSlideModal()
    } catch (error) {
      showToast('error', 'スライドの編集に失敗しました')
    }
  }

  return {
    isOpenEditSlideModal,
    onOpenEditSlideModal,
    onCloseEditSlideModal,
    isEditSlideLoadingRequest,
    handleFiles,
    onEditSlide,
  }
}
