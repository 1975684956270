import { useDisclosure } from '@chakra-ui/react'
import { ChangeEventHandler, useState , useRef, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import useSwrFetch from '../../../common/hooks/useSwrFetch'
import { currentSlideState } from '../../../common/stores/currentSlide'
import { format } from '../../../common/util/format'
import { FlowState } from '../types/flow'
import { useMyToast } from '../../../common/hooks/useMyToast'


export const useCreateAction = () => {
  const params = useParams()
  const { showToast } = useMyToast()

  const { timerFormat } = format()

  const { isOpen: audioIsOpen, onOpen: audioOnOpen, onClose: audioOnClose } = useDisclosure()
  const { isOpen: waitIsOpen, onOpen: waitOnOpen, onClose: waitOnClose } = useDisclosure()
  const { isOpen: timerIsOpen, onOpen: timerOnOpen, onClose: timerOnClose } = useDisclosure()
  const { isOpen: screenIsOpen, onOpen: screenOnOpen, onClose: screenOnClose } = useDisclosure()
  const { isOpen: chatIsOpen, onOpen: chatOnOpen, onClose: chatOnClose } = useDisclosure()
  const { isOpen: codeIsOpen, onOpen: codeOnOpen, onClose: codeOnClose } = useDisclosure()

  const [audioUrl, setAudioUrl] = useState("");

  const [micinAudiofile, setMicinAudioFile] =  useState<Blob[]>([]);
  const [micinAudioState, setMicinAudioState] = useState(true);
  const audioRef = useRef<MediaRecorder | null>(null);
  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);

  
  
  const {
    data: flow,
    request,
    isLoadingRequest: isCreateActionLoading,
  } = useSwrFetch<FlowState, FormData>(`/show/${params.flowId}`)

  const currentSlide = useRecoilValue(currentSlideState)

  const [audio, setAudio] = useState<File>()
  const [isAudioError, setIsAudioError] = useState(false)

  const [waitHour, setWaitHour] = useState(0)
  const [waitMinute, setWaitMinute] = useState(0)
  const [waitSecond, setWaitSecond] = useState(0)

  const [timerFormatType, setTimerFormatType] = useState('hour')
  const [timerHour, setTimerHour] = useState(0)
  const [timerMinute, setTimerMinute] = useState(0)
  const [timerSecond, setTimerSecond] = useState(0)

  const [chatMessage, setChatMessage] = useState('')

  const [codeScript, setCodeScript] = useState('')

  const handleMicSuccess = (stream: MediaStream) => {

    console.log("サクセス")
    const options = { mimeType: "audio/webm" };
    audioRef.current = new MediaRecorder(stream, options);
    let chunks: Blob[] = [];
    audioRef.current.ondataavailable = (event: BlobEvent) => {
      if (event.data.size > 0) {
        chunks.push(event.data);
      }
      setMicinAudioFile(chunks);
    };
    audioRef.current.onstart = () => setMicinAudioState(false);
    audioRef.current.onstop = async() => {
      setMicinAudioState(true);
      const blob = new Blob(chunks, { type: "audio/webm" });
      const reader = new FileReader()
      reader.readAsArrayBuffer(blob);
      reader.onload = async() => {
        const audioCtx = new AudioContext();
        const audioData = reader.result as ArrayBuffer;
        audioCtx.decodeAudioData(audioData).then(async buf => {
          const b = buf.getChannelData(0)
          const wavBlob = await convertToWav(b);
          const audioFile = new File([wavBlob], "recording.wav", { type: "audio/wav" });
          setAudio(audioFile)
          let url = URL.createObjectURL(audioFile);
          if (audioPlayerRef.current) {
            audioPlayerRef.current.src = url;
            audioPlayerRef.current.play();
          }
          chunks = [];
        })
      };

      };
  };

  const handleMicError = () => {
    alert("エラーです。");
  };

  useEffect(() => {
    // マイクへのアクセス権を取得

    //audioのみtrue
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: false,
      })
    .then(handleMicSuccess)
    .catch(handleMicError);
  
  }, []);

  const writeString = (view: DataView, offset: number, str: string): void => {
    for (let i = 0; i < str.length; i++) {
      view.setUint8(offset + i, str.charCodeAt(i));
    }
  };
  const convertToWav = async (chunks: Float32Array): Promise<Blob> => {
    // ここでchunksをWAV形式に変換する処理を行う
    // この部分は具体的なWAV変換処理に依存します
    // WAVファイルのヘッダ情報を設定
    
    let audioContext = new AudioContext();
    let audioSampleRate = audioContext.sampleRate;
    let frameCount = audioSampleRate * 2.0;
    const bitDepth = 16;
    const format = 1;
    const  bytesPerSample = bitDepth / 8
    let headerSize = 44;

    let totalSize = 0;
    totalSize = chunks.length * 2;

    const view = new DataView(new ArrayBuffer(headerSize + totalSize));
  

    // RIFFヘッダ
    writeString(view, 0, 'RIFF');  // RIFFヘッダ
    //view.setUint32(4, 32 + chunks.length * 2, true);
    view.setUint32(4, 32 + totalSize, true); // 4バイトのファイルサイズフィールド

    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');  // fmt サブチャンク
    view.setUint32(16, 16, true); // サブチャンク1のサイズ
    view.setUint16(20, 1, true); // オーディオフォーマット（PCM = 1）
    view.setUint16(22, 1, true); // チャンネル数
    view.setUint32(24, audioSampleRate, true); // サンプルレート
    view.setUint32(28, audioSampleRate * 2, true); // バイトレート（サンプルレート * ブロックアライン）
    view.setUint16(32, 2, true); // ブロックアライン（チャンネル数 * バイト / サンプル）
    view.setUint16(34, 16, true); // サンプルあたりのビット数
    writeString(view, 36, 'data'); // data サブチャンク
    view.setUint32(40, totalSize, true); // 4バイトのデータサイズフィールド


    for (var i = 0; i < chunks.length; i++, headerSize += 2) {
      var s = Math.max(-1, Math.min(1, chunks[i]))
      view.setInt16(headerSize, s < 0 ? s * 0x8000 : s * 0x7FFF, true)
    }
    // DataViewをBlobに変換
    return new Blob([view], { type: 'audio/wav' });
  };


  // 録音開始
  const handleStart = () => {
    if (audioRef.current) {
      audioRef.current.start();
    }
  };

  // 録音停止
  const handleStop = () => {
    if (audioRef.current) {
      audioRef.current.stop();
    }
  };

  const handleRemove = () => {
    setMicinAudioState(true);
    setMicinAudioFile([]);
  };

  
  const updateJsonToFlow = async (slides: FlowState['slides'], actionType: string) => {
    const formData = new FormData()
    const json = JSON.stringify({ slides })
    formData.append('json', json)
    try {
      await request(`/updateJson/${params.flowId}`, 'post', formData)
      showToast('success', `${actionType}アクションを追加しました`)
    } catch (error) {
      showToast('error', `${actionType}アクションの追加に失敗しました`)
    }
  }

  const addActionToFlow = (actionType: string, param: any) => {
    if (!flow) return

    const newAction = { type: actionType, param }
    const afterActions = [...flow.slides[currentSlide].actions, newAction]

    const afterSlides = flow.slides.map((slide, index) => ({
      ...slide,
      actions: index === currentSlide ? afterActions : slide.actions,
    }))
    updateJsonToFlow(afterSlides, actionType)
  }

  const addShareScreen = () => {
    addActionToFlow('画面共有', 'スライド表示：')
    screenOnClose()
  }

  const handleAudio: ChangeEventHandler<HTMLInputElement> = (event) => {
    const files = event.currentTarget.files
    if (!files || files?.length === 0) return
    const file = files[0]

    // ドラッグ＆ドロップからファイル選択する際のファイル型を制限
    if (!file.type.includes('wav')) {
      event.currentTarget.value = ''
      showToast('error', 'wavファイルを選択してください')
      return
    }
    setAudio(file)
    let url = URL.createObjectURL(file);
    if (audioPlayerRef.current) {
      audioPlayerRef.current.src = url;
      audioPlayerRef.current.play();
    }
  }

  const resetAudioOnClose = () => {
    setAudio(undefined)
    setIsAudioError(false)
    audioOnClose()
  }

  const resetChatOnClose = () => {
    setChatMessage('')
    chatOnClose()
  }

  const resetWaitOnClose = () => {
    setWaitHour(0)
    setWaitMinute(0)
    setWaitSecond(0)
    waitOnClose()
  }

  const resetTimerOnClose = () => {
    setTimerHour(0)
    setTimerMinute(0)
    setTimerSecond(0)
    timerOnClose()
  }

  const resetCodeOnClose = () => {
    setCodeScript('')
    codeOnClose()
  }

  const addAudioAction = async () => {
    if (!flow) return

    if (audio === undefined) {
      setIsAudioError(true)
      return
    }

    // const audioURL = window.URL.createObjectURL(audio)
    const formData = new FormData()
    formData.append('file', audio)
    try {
      const res = await request(`/uploadFile/${params.flowId}`, 'post', formData)
      const audioPaths: string[] = res.data.paths
      addActionToFlow('音声', audioPaths[0])
    } catch (error) {
      showToast('error', 'ファイルアップロードに失敗しました')
    } finally {
      resetAudioOnClose()
    }
  }

  const addChatAction = () => {
    addActionToFlow('チャット', chatMessage)
    resetChatOnClose()
  }

  const addWaitAction = () => {
    if (!flow) return

    // 待ち時間アクション処理
    let totalWaitSec = 0
    if (waitHour) {
      totalWaitSec = totalWaitSec + waitHour * 3600
    }
    if (waitMinute) {
      totalWaitSec = totalWaitSec + waitMinute * 60
    }
    if (waitSecond) {
      totalWaitSec = totalWaitSec + waitSecond
    }
    addActionToFlow('待ち時間', totalWaitSec)
    resetWaitOnClose()
  }

  const addTimerAction = async () => {
    //1スライドにタイマーは、１つのみ
    if (!flow) return
    const isTimer = flow.slides[currentSlide].actions.find((action) => action.type === 'タイマー')
    if (isTimer) {
      showToast('error', 'タイマーは、1スライドに1度のみです')
      resetTimerOnClose()
      return
    }

    const formattedTime = timerFormat(timerFormatType, timerHour, timerMinute, timerSecond)
    addActionToFlow('タイマー', formattedTime)
    resetTimerOnClose()
  }

  const addCodeAction = async () => {
    addActionToFlow('コード', codeScript)
    resetCodeOnClose()
  }

  return {
    addShareScreen,
    audioIsOpen,
    audioOnOpen,
    resetAudioOnClose,
    addAudioAction,
    handleAudio,
    screenIsOpen,
    screenOnOpen,
    screenOnClose,
    chatIsOpen,
    chatOnOpen,
    resetChatOnClose,
    waitIsOpen,
    waitOnOpen,
    resetWaitOnClose,
    addWaitAction,
    waitHour,
    waitMinute,
    waitSecond,
    setWaitHour,
    setWaitMinute,
    setWaitSecond,
    isAudioError,
    addChatAction,
    chatMessage,
    setChatMessage,
    timerIsOpen,
    timerOnOpen,
    resetTimerOnClose,
    addTimerAction,
    timerFormatType,
    setTimerFormatType,
    timerHour,
    timerMinute,
    timerSecond,
    setTimerHour,
    setTimerMinute,
    setTimerSecond,
    codeIsOpen,
    codeOnOpen,
    resetCodeOnClose,
    addCodeAction,
    codeScript,
    setCodeScript,
    isCreateActionLoading,
    micinAudioState,
    handleStart,
    handleStop,
    handleRemove,
    micinAudiofile,
    audioPlayerRef
  }
}
