import {
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  ModalBody,
  ModalHeader,
  Skeleton,
} from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'
import { MyModal } from '../../../common/components/Modal'
import { currentSlideState } from '../../../common/stores/currentSlide'
import { useEditSlide } from '../hooks/useEditSlide'
import { useGetFlowContents } from '../hooks/useGetFlowContents'

export const Slide = () => {
  const { data: flow, isLoading } = useGetFlowContents()

  const currentSlide = useRecoilValue(currentSlideState)

  const {
    isOpenEditSlideModal,
    onCloseEditSlideModal,
    onOpenEditSlideModal,
    onEditSlide,
    isEditSlideLoadingRequest,
    handleFiles,
  } = useEditSlide()

  if (isLoading) return <>...loading</>

  return (
    <>
      <>
        {flow && flow.slides[currentSlide] && flow.slides[currentSlide].id ? (
          <Image
            src={flow.slides[currentSlide].id}
            fallback={<Skeleton w={'full'} h={'full'}></Skeleton>}
            cursor={'pointer'}
            onClick={onOpenEditSlideModal}
          />
        ) : (
          <Skeleton bg={'blue'} />
        )}
      </>
      <MyModal
        isOpen={isOpenEditSlideModal}
        onClose={onCloseEditSlideModal}
        onSubmit={onEditSlide}
        isLoading={isEditSlideLoadingRequest}
      >
        <ModalHeader>スライドを編集</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>ファイルを編集する</FormLabel>
            <input type={'file'} accept={'.svg'} onChange={handleFiles} />
            <FormHelperText>SVGファイルを編集(最大10M)</FormHelperText>
          </FormControl>
        </ModalBody>
      </MyModal>
    </>
  )
}
