import { Routes, Route, BrowserRouter } from 'react-router-dom'
import './App.css'
import Flows from './category/flows/components/Flows'
import { ChakraProvider } from '@chakra-ui/react'
import { Section } from './category/section/components/Section'
import { RecoilRoot } from 'recoil'

function App() {
  return (
    <RecoilRoot>
      <ChakraProvider>
        <BrowserRouter basename="">
          <Routes>
            <Route path="/" element={<Flows />} />
            <Route path="/:flowId/sections/:sectionId" element={<Section />} />
            <Route path="/add" element={<Section />} />
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </RecoilRoot>
  )
}

export default App
